import React from "react";
import Button from "../common/button/Button";

const ContactCard = ({ title, desc, btnTitle, btnLink, icon }) => {
  return (
    <div className="w-full h-full pl-4 flex justify-center items-center flex-col text-white">
      {icon}
      <h2 className="text-2xl tracking-wide my-6">{title}</h2>

      <p className="mb-7 text-xs text-center tracking-wide text-slate-200">
        {desc}
      </p>
      <Button
        title={btnTitle}
        link={btnLink}
      />
    </div>
  );
};

export default ContactCard;
