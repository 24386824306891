import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Autoplay } from 'swiper/modules';
// import Swiper and modules styles
import 'swiper/css';
import 'swiper/css/navigation';
import { sliderSource } from '../../constant/slider';
import HeaderDarkFilter from './HeaderDarkFilter';
import Project from './Project';
const Header = () => {
  return (
    <div className='w-full relative h-[80vh]'>
      <Swiper
        loop={true}
        spaceBetween={10}
        autoplay={{
          delay: 3100,
          disableOnInteraction: false,
        }}
        navigation={true}
        modules={[Navigation, Autoplay]}
        className='w-full h-full'
      >
        {sliderSource.map((item) => (
          <SwiperSlide key={item.id} className='relative  h-full w-full'>
            <HeaderDarkFilter />
            <img src={item.src} alt='' className='w-full h-full object-cover' />
          </SwiperSlide>
        ))}
      </Swiper>

      <Project />
    </div>
  );
};

export default Header;
