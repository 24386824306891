import fabrika from '../assets/IMG_20220412_162537.jpg';
import ev from '../assets/houseCleaning.jpg';
import magaza from '../assets/magaza.jpg';
import makina from '../assets/makina.jpg';
import endust from '../assets/endust.jpg';
import sinema from '../assets/sinema.jpg';
import ofis from '../assets/ofis.jpg';
import havuz from '../assets/havuz.jpg';
import dıs from '../assets/IMG_20220131_144715.jpg';
import dısCephe from '../assets/disCephe.jpg';
import fayans from '../assets/fayans.jpg';
import yangın from '../assets/yangin.jpg';
import cami from '../assets/cami.jpg';
import InsaatSonrası from '../assets/InsaatSonrasi.jpeg';
import villa from '../assets/villa.jpeg';
import cit from '../assets/cit.jpeg';
import kaynak from '../assets/kaynak.jpeg';
import hafriyat from '../assets/hafriyat.jpeg';
import onarım from '../assets/onarim.jpeg';
import boya from '../assets/boya.jpeg';
import icDesign from '../assets/icTasarim.jpeg';
import dis from '../assets/dis.jpeg';

export const cleaningServices = [
  {
    id: 'service1',
    title: 'Fabrika Temizliği',
    desc: `Fabrika temizliği, üretim verimliliğini artırır ve iş güvenliğini sağlar. Profesyonel hizmetler müşteri güvenini artırır.`,
    src: fabrika,
  },

  {
    id: 'service2',
    title: 'Ev Temizliği',
    desc: `Ev temizliği, sağlık ve konfor için önemlidir. Profesyonel hizmetler zaman kazandırır. Modern temizlik ekipmanları yaşam kalitesini artırır.`,
    src: ev,
  },

  {
    id: 'service3',
    title: 'Mağaza Temizliği',
    desc: `Mağaza temizliği, müşteri memnuniyetini artırır. Profesyonel hizmetler itibarı güçlendirir. Modern ekipmanlar deneyimi iyileştirir.`,
    src: magaza,
  },

  {
    id: 'service4',
    title: 'Makina Temizliği',
    desc: `Makina temizliği, verimliliği artırır. Profesyonel hizmetler ömrü uzatır. Modern yöntemler güvenliği sağlar.`,
    src: makina,
  },
  {
    id: 'service5',
    title: 'Endüstriyel Temizlik',
    desc: `Endüstriyel temizlik, iş verimliliğini artırır. Profesyonel hizmetler kaliteyi yükseltir. Modern yöntemler hijyen sağlar.`,
    src: endust,
  },
  {
    id: 'service6',
    title: 'Sinema Temizliği',
    desc: `Sinema temizliği, konforu artırır. Profesyonel hizmetler deneyimi geliştirir. Modern yöntemler hijyen sağlar.`,
    src: sinema,
  },
  {
    id: 'service7',
    title: 'Ofis Temizliği',
    desc: `Ofis temizliği, verimliliği artırır. Profesyonel hizmetler hijyen sağlar. Modern yöntemlerle temizlik kolaylaşır.`,
    src: ofis,
  },
  {
    id: 'service8',
    title: 'Havuz Temizliği',
    desc: `Havuz temizliği, su kalitesini artırır. Profesyonel hizmetler güvenliği sağlar. Modern yöntemlerle hijyen korunur.`,
    src: havuz,
  },
  {
    id: 'service9',
    title: 'Dış Cephe Cam Temizliği',
    desc: `Dış cephe cam temizliği, bina görünümünü yeniler. Profesyonel hizmetler parlaklık sağlar.`,
    src: dıs,
  },

  {
    id: 'service10',
    title: 'Dış Cephe  Temizliği',
    desc: `Dış cephe temizliği, bina estetiğini artırır. Profesyonel hizmetler kalite sunar. Modern yöntemlerle temizlik kolaylaşır.`,
    src: dısCephe,
  },

  {
    id: 'service11',
    title: 'Duvar Fayans temizliği',
    desc: `Duvar fayans temizliği, mekanları yeniler. Profesyonel hizmetler kalıcı çözümler sunar. Modern yöntemlerle kolaylık sağlanır.`,
    src: fayans,
  },

  {
    id: 'service12',
    title: 'Yangın Sonrası Temizlik',
    desc: `Yangın sonrası temizlik, hızlı ve güvenilir çözümler sunar. Profesyonel hizmetler en iyi sonucu garanti eder.`,
    src: yangın,
  },

  {
    id: 'service13',
    title: 'Cami Temizliği',
    desc: `Cami temizliği, ibadet ortamını yeniler. Profesyonel hizmetler hijyen sağlar. Modern ekipmanlarla temizlik kolaylaşır.`,
    src: cami,
  },

  {
    id: 'service14',
    title: 'İnşaat Sonrası Temizlik',
    desc: `İnşaat sonrası temizlik, çevreci malzemelerle ve titizlikle yapılır, müşteri memnuniyeti garantilidir.`,
    src: InsaatSonrası,
  },

  {
    id: 'service15',
    title: 'AVM Temizliği',
    desc: `İnşaat sonrası temizlik, çevreci malzemelerle ve titizlikle yapılır, müşteri memnuniyeti garantilidir.`,
    src: InsaatSonrası,
  },

  {
    id: 'service16',
    title: 'Villa Temizliği',
    desc: `Villa temizliği, deneyimli ekibimiz tarafından özenle gerçekleştirilirken, müşterilerimize hijyen ve konfor sunuyoruz.`,
    src: villa,
  },

  {
    id: 'service17',
    title: 'Okul Temizliği',
    desc: `Okul temizliği, öğrencilerin sağlığını ve öğrenme ortamını destekleyerek eğitim alanlarını hijyenik ve güvenli hale getiriyoruz.`,
    src: ofis,
  },
];

export const buildingService = [
  {
    id: 'service1',
    title: 'Çit Duvar Örme',
    desc: `Çit duvar örme hizmetimiz, uzman ekiplerimizle titizlikle gerçekleştirilir, müşteri taleplerini karşılarız.`,
    src: cit,
  },

  {
    id: 'service2',
    title: 'Kaynak İşleri',
    desc: `Kaynak işleri, deneyimli ekiplerimiz tarafından titizlikle gerçekleştirilir, müşteri memnuniyetini ön planda tutarız.`,
    src: kaynak,
  },
  {
    id: 'service3',
    title: 'Hafriyat Temizliği',
    desc: `Kaynak işleri, deneyimli ekiplerimiz tarafından titizlikle gerçekleştirilir, müşteri memnuniyetini ön planda tutarız.`,
    src: hafriyat,
  },

  {
    id: 'service4',
    title: 'Onarım Tadilat Hizmeti',
    desc: `Onarım tadilat hizmetimiz, uzman ekiplerle titizlikle yürütülür.`,
    src: onarım,
  },

  {
    id: 'service5',
    title: 'Boya Badana Hizmeti',
    desc: `Boya badana hizmetimiz, uzmanlarımızca titizlikle gerçekleştirilir, kaliteli sonuçlar sunarız.`,
    src: boya,
  },

  {
    id: 'service6',
    title: 'İç Dizayn',
    desc: `İç dizayn hizmetimiz, uzmanlarımızca özenle gerçekleştirilir, mekânlarınıza estetik ve fonksiyonellik katarız.`,
    src: icDesign,
  },
  {
    id: 'service7',
    title: 'Dış dizayn',
    desc: `Dış dizayn hizmetimiz, uzmanlarımızca titizlikle yürütülür, mekânlarınıza estetik ve işlevsellik katarız.`,
    src: dis,
  },
];
