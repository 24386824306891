import React from 'react';
import { Link } from 'react-router-dom';

const FooterLink = ({ link, title }) => {
  return (
    <li>
      <Link
        to={link}
        className='hover:underline hover:text-white  me-4 md:me-6'
      >
        {title}
      </Link>
    </li>
  );
};

export default FooterLink;
