import React from 'react';
import HeaderDarkFilter from '../../Home/HeaderDarkFilter';

const Header = ({ title, src }) => {
  return (
    <header className='w-full h-[40vh] md:text-2xl text-3xl lg:text-7xl font-semibold text-white flex justify-center items-center relative'>
      <HeaderDarkFilter />
      <span className=' transform w-full text-center top-1/2 left-1/2 absolute -translate-y-1/2 -translate-x-1/2 z-10'>
        {title}
      </span>
      <img src={src} className='w-full h-full object-cover' alt='' />
    </header>
  );
};

export default Header;
