export const aboutValue = [
  {
    id: 1,
    desc: `YAPITTEM, temizlik sektöründe uzmanlaşmış bir şirkettir. 2002 yılında kurulan firmamız, uzun yıllara dayanan deneyimi ve sektördeki lider konumuyla müşterilerine en iyi temizlik çözümlerini sunmaktadır. Müşterilerimizin memnuniyeti ve sağlığı bizim önceliğimizdir.`,
  },

  {
    id: 2,
    desc: `ISO 9001:2000 Kalite Yönetim sisteminede sahip olan firmamız , devam eden projelerinde tüm saha ekibi ile birlikte kalite kontrol ve İş güvenliği konularınıda hassasiyetle takip etmektedir. YAPITTEM olarak amacımız , Türkiyenin lokomotifi olan İnşaat Sektörünün içinde kalite ve İş güvenliğinden ödün vermeden firmamızın yükselişini devam ettirmektir.`,
  },
];
