import React from 'react';

const ServiceCard = ({ src, desc, title, id }) => {
  return (
    <div
      id={id}
      className='flex justify-center items-center flex-col  w-full h-full relative group overflow-hidden shadow rounded-lg'
    >
      <img src={src} alt='' className=' object-cover h-24 sm:h-60 w-full' />

      <div className=' w-full h-full py-2 sm:py-5 text-center px-2'>
        <h2 className='text-xl sm:text-2xl tracking-wide my-2'>{title}</h2>
        <p className='text-slate-600  tracking-wide text-center text-sm'>{desc}</p>
      </div>
    </div>
  );
};

export default ServiceCard;
