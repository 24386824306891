import React from 'react';
import { Link } from 'react-router-dom';
import logo from '../../../../../assets/yap_tem.png';
const FooterLogo = () => {
  return (
    <Link to='/' className='flex items-center mb-4 sm:mb-0'>
      <img src={logo} className='h-20' alt='Yapıtem Logo' />
    </Link>
  );
};

export default FooterLogo;
