
import { useEffect, useState } from "react";
import { navbarBackgroundHandler } from "../utils/NavbarFunctions";

const useNavbar = () => {
  const [navbar, setNavbar] = useState(null);
  const [isActive, setIsActive] = useState(false);

  useEffect(() => {
    navbarBackgroundHandler(setNavbar, isActive);
  });

  return { navbar, setNavbar, isActive, setIsActive };
};

export default useNavbar;
