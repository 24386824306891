import React from "react";
import { Link } from "react-router-dom";

const Button = ({ title, link, type, filled }) => {
  return (
    <Link
      to={link}
      className={`${
        type
          ? " inline-block border-sky-500 hover:bg-sky-500 duration-300 border px-14 py-4 text-lg text-sky-500 hover:text-white rounded-lg bg-none "
          : " inline-block border-sky-500 hover:bg-sky-500 duration-300 border px-14 py-4 text-lg text-slate-50 rounded-lg bg-none "
      }  ${
        filled
          ? "inline-block border-sky-500 bg-sky-500 duration-300 border px-14 py-4 text-lg  text-white rounded-lg hover:bg-transparent"
          : ""
      }`}
    >
      {title}
    </Link>
  );
};

export default Button;
