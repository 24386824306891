import React from 'react';
import HeaderDarkFilter from './HeaderDarkFilter';
import ContactCard from './ContactCard';
import { contactCard } from '../../constant/contact';
import ContactBackground from './ContactBackground';

const Contact = () => {
  return (
    <div className='w-full h-screen lg:h-[70vh] relative mt-16'>
      <HeaderDarkFilter />
      <ContactBackground />

      <div className='w-full h-full absolute transform -translate-y-1/2 -translate-x-1/2 top-1/2 left-1/2 flex lg:flex-row flex-col justify-center items-center gap-10 z-10'>
        {contactCard.map((card) => (
          <ContactCard
            key={card.id}
            title={card.title}
            btnTitle={card.btnTitle}
            desc={card.desc}
            icon={card.icon}
            link={card.btnLink}
          />
        ))}
      </div>
    </div>
  );
};

export default Contact;
