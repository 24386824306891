import React, { useEffect } from 'react';
import Header from '../components/common/header/Header';
import header from '../assets/slider.jpg';
import { referenceValue } from '../constant/references';
import userPicture from '../assets/user.png';
import gsap from 'gsap';

const ReferencesPage = () => {
  useEffect(() => {
    window.scrollTo(0,0)
    const TL = gsap.timeline({
      defaults: {
        delay: -0.25,
        duration: 0.5,
        ease: 'back.out(1.7)',
      },
    });

    TL.fromTo('#plus', { autoAlpha: 0, y: 50 }, { autoAlpha: 1, y: 0 })
      .fromTo('#beta', { autoAlpha: 0, y: 50 }, { autoAlpha: 1, y: 0 })
      .fromTo('#software', { autoAlpha: 0, y: 50 }, { autoAlpha: 1, y: 0 })
      .fromTo('#school', { autoAlpha: 0, y: 50 }, { autoAlpha: 1, y: 0 })
      .fromTo('#government', { autoAlpha: 0, y: 50 }, { autoAlpha: 1, y: 0 })
      .fromTo('#union', { autoAlpha: 0, y: 50 }, { autoAlpha: 1, y: 0 });
  }, []);

  return (
    <div>
      <Header src={header} title='Referanslarımız' />
      <div className='my-16 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-16 w-full min-h-[35vh] container mx-auto'>
        {referenceValue.map((ref) => (
          <div
            key={ref.id}
            id={ref.tag}
            className='bg-white group border rounded-lg text-center flex justify-center items-center flex-col gap-2   shadow  w-full h-full relative p-6'
          >
            <h2 className='text-slate-800 text-2xl mt-5'>{ref.name}</h2>
            <p className='text-slate-500 tracking-wide'>{ref.company}</p>

            <div className='w-16 h-16 rounded-full bg-white shadow-sm  absolute top-0 border left-1/2 transform -translate-y-1/2 -translate-x-1/2'>
              <img
                src={userPicture}
                alt='User Icon'
                className='w-full h-full object-cover'
              />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ReferencesPage;
