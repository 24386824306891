import React from 'react'
import NavbarLogo from './components/NavbarLogo'

const NavbarLeft = () => {
  return (
    <div>
      <NavbarLogo />
    </div>
  )
}

export default NavbarLeft
