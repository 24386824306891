import React, { useEffect } from 'react';

import NavbarLeft from './components/navbarLeft/NavbarLeft';
import NavbarRight from './components/navbarRight/NavbarRight';
import NavbarTop from './components/navbarTop/NavbarTop';
import { navbarBackgroundHandler } from '../../../utils/NavbarFunctions';
import { UpdateWindowDimensions } from '../../../utils/NavbarFunctions';
import useNavbar from '../../../hooks/UseNavbar';
import NavbarMobile from './components/navbarMobile/NavbarMobile';
import HamburgerMenu from './components/navbarRight/components/hamburgerMenu/HamburgerMenu';

const Navbar = () => {
  const { navbar, isActive, setNavbar, setIsActive } = useNavbar();

  useEffect(() => {
    window.addEventListener(
      'scroll',
      navbarBackgroundHandler.bind(null, setNavbar, isActive)
    );

    window.addEventListener(
      'resize',
      UpdateWindowDimensions.bind(null, setIsActive)
    );

    return () => window.removeEventListener('resize', UpdateWindowDimensions);
  }, []);

  return (
    <>
      <nav
        className={`fixed top-0 w-full z-50   duration-300  
  ${
    navbar
      ? '  bg-yapıtem-100 text-slate-400 '
      : 'bg-transparent text-slate-200 '
  }`}
      >
        <NavbarTop navbar={navbar} />
        <div className='w-full container mx-auto  items-center mt-2 py-2 px-4  justify-between flex gap-8'>
          <NavbarLeft />
          <NavbarRight />

          <HamburgerMenu isActive={isActive} setIsActive={setIsActive} />
        </div>
      </nav>
      <NavbarMobile isActive={isActive} setIsActive={setIsActive} />
    </>
  );
};

export default Navbar;
