import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Home from './page/Home';
import Navbar from './components/common/navbar/Navbar';
import Footer from './components/common/footer/Footer';
import AboutPage from './page/About';
import ReferencesPage from './page/References';
import ContactPage from './page/Contact';
import NotFound from './page/NotFound';
import CleaningServices from './page/CleaningServices';
import BuildingServices from './page/BuildingServices';

function App() {
  return (
    <>
      <BrowserRouter>
        <Navbar />
        <Routes>
          <Route index element={<Home />} />
          <Route path='/about' element={<AboutPage />} />
          <Route path='/cleaning' element={<CleaningServices />} />
          <Route path='/building' element={<BuildingServices />} />
          <Route path='/references' element={<ReferencesPage />} />
          <Route path='/contact' element={<ContactPage />} />
          <Route path='*' element={<NotFound />} />
        </Routes>
        <Footer />
      </BrowserRouter>
    </>
  );
}

export default App;
