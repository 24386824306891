import React, { useEffect, useRef } from 'react';
import ServiceCard from './ServiceCard';
import { cleaningServices } from '../../constant/service';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);
const Services = () => {
  const titleRef = useRef();

  useEffect(() => {
    const tl = (titleRef.current = gsap.timeline({
      defaults: {
        delay: -0.25,
        duration: 0.5,
        ease: 'back.out(1.7)',
        scrollTrigger: {
          trigger: '.start',
          start: 'top bottom-=300',
          scrub: 1,
          invalidateOnRefresh: true,
        },
      },
    }));

    cleaningServices.map((item, index) => {
      tl.fromTo(
        `#service${index}`,
        { autoAlpha: 0, y: 50 },
        { autoAlpha: 1, y: 0 }
      );
    }, []);
  });

  return (
    <>
      <h2 className='text-center text-5xl text-sky-500 font-semibold py-24  start'>
        Hizmetlerimiz
      </h2>

      <div
        ref={titleRef}
        className='w-full h-full py-12 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 justify-center items-center gap-10 container mx-auto'
      >
        {cleaningServices.slice(0, 8).map((service) => (
          <ServiceCard
            id={service.id}
            desc={service.desc}
            src={service.src}
            title={service.title}
            key={service.id}
          />
        ))}
      </div>
    </>
  );
};

export default Services;
