export const referenceValue = [
  {
    id: 1,
    name: 'Şüheda Hanım ',
    company: 'Beta Plus',
    tag: 'plus',
  },
  {
    id: 2,
    name: 'Elif Hanım ',
    company: 'Beta Oyun',
    tag: 'beta',
  },
  {
    id: 3,
    name: 'Eser Bey',
    company: 'Logsing Yazılım',
    tag: 'software',
  },
  {
    id: 4,
    name: 'Maksude Hanım',
    company: 'Ataşehir Şehit Öğretmen Hasan Akan İlköğretim Okulu',
    tag: 'school',
  },
  {
    id: 5,
    name: 'Kerem Bey',
    company: 'Kartal Belediyesi Temizlik İşleri Müdürü',
    tag: 'government',
  },
  {
    id: 6,
    name: 'Veral Bey',
    company: 'Avrupa Yakası Site Birliği Yönetimi',
    tag: 'union',
  },
];
