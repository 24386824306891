import hero1 from "../assets/slider.jpg"
import hero2 from "../assets/slider1.jpg"
import hero3 from "../assets/slider3.jpg"
import hero4 from "../assets/slider2.jpg"


export const sliderSource = [
    {
        id:1,
        src: hero1
    },

    {
        id:2,
        src: hero2
    },

    {
        id:3,
        src: hero3
    },

    {
        id:4,
        src: hero4
    },
]