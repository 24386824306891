import React from "react";
import { navbarLinks } from "../../../../../constant/navbar";
import { Link } from "react-router-dom";


const NavbarRight = () => {
  return (
    <>
      <div className="w-full hidden lg:flex items-center  justify-end gap-8 ">
        {navbarLinks.map((item) => (
          <Link
            className="hover:text-white duration-200"
            to={item.link}
            key={item.id}
          >
            {item.title}
          </Link>
        ))}
      </div>
    </>
  );
};

export default NavbarRight;
