import React, { useEffect, useRef } from 'react';
import aboutImage from '../../assets/slider2.jpg';
import AboutDesc from '../../components/About/AboutDesc.jsx';
import { aboutValue } from '../../constant/about.js';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);
const About = () => {
  const titleRef = useRef();
  useEffect(() => {
    const tl = gsap.timeline({
      defaults: {
        delay: -0.25,
        duration: 0.9,
        scrollTrigger: {
          trigger: titleRef.current,
          start: 'top bottom-=300',
        },
      },
    });

    tl.fromTo(
      '.aboutSection1',
      { opacity: 0, y: 100 },
      { opacity: 1, y: 0 }
    ).fromTo('.aboutSection2', { opacity: 0, y: 100 }, { opacity: 1, y: 0 });
  }, []);

  return (
    <div className='w-full h-full py-10   container mx-auto grid grid-cols-1 lg:grid-cols-2 gap-16 justify-center items-center'>
      <img
        src={aboutImage}
        alt=''
        className='object-cover shadow-lg rounded lg:block hidden'
      />

      <div className='aboutSection1 flex tracking-wide leading-loose text-justify px-6 lg:px-0 lg:text-start flex-1 flex-col justify-center items-start'>
        <h2
          ref={titleRef}
          className='text-center lg:text-start w-full text-5xl text-sky-500 font-semibold py-10 '
        >
          Hakkımızda
        </h2>
        {aboutValue.map((about) => (
          <AboutDesc desc={about.desc} key={about.id} />
        ))}
      </div>
    </div>
  );
};

export default About;
