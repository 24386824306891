import React, { useEffect } from 'react';
import heroImage from '../assets/slider1.jpg';
import HeaderDarkFilter from '../components/Home/HeaderDarkFilter';
import ContactCard from '../components/Contact/ContactCard';
import { contactPage } from '../constant/contact';

const ContactPage = () => {

  useEffect(()=>{
    window.scrollTo(0,0)
  })
  return (
    <div>
      <div className='w-full h-[80vh] flex justify-center items-center relative'>
        <HeaderDarkFilter />
        <img src={heroImage} alt='' className='w-full h-full object-cover' />
        <div className='absolute top-1/2 left-1/2 transform z-10 -translate-y-1/2 -translate-x-1/2'>
          <h2 className='text-white tracking-wide text-center text-6xl font-semibold'>
            İletişim
          </h2>
          <p className='tracking-wide text-slate-100 text-center text-xs mt-7 '>
            Bize ulaşın, sorularınızı, geri bildirimlerinizi ve işbirliği
            fırsatlarınızı bekliyoruz. İletişim formumuzu doldurarak bize
            kolayca ulaşabilir veya doğrudan e-posta veya telefon ile iletişime
            geçebilirsiniz. Size en kısa sürede yanıt vermeyi taahhüt ediyoruz.
          </p>
        </div>

        <div
          className='absolute  lg:-bottom-1/2 -bottom-[100%] left-1/2 transform z-10 -translate-y-0 lg:-translate-y-1/2 
        -translate-x-1/2 bg-yapıtem-100 opacity-95  text-white container mx-auto shadow-md overflow-hidden w-full min-h-svh lg:min-h-0 lg:h-96 rounded-xl grid lg:grid-cols-3 lg:divide-x divide-x-0  divide-slate-500 
        justify-center items-center gap-0 lg:gap-10 px-5 pb-5  lg:p-10'
        >
          {contactPage.map((contact) => (
            <ContactCard
              title={contact.btnTitle}
              btnLink={contact.btnLink}
              btnTitle={contact.btnTitle}
              key={contact.id}
              desc={contact.desc}
              icon={contact.icon}
            />
          ))}
        </div>
      </div>

      <div className='h-screen lg:h-[30vh] w-full'></div>
    </div>
  );
};

export default ContactPage;
