import React, { useEffect, useRef } from 'react';
import HeaderDarkFilter from './HeaderDarkFilter';
import Button from '../common/button/Button';
import gsap from 'gsap';

import { ScrollTrigger } from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);

const WhatWeDo = () => {
  const headerRef = useRef();
  useEffect(() => {
    const el = headerRef.current;

    gsap.fromTo(
      el,
      { y: 100, opacity: 0 },
      {
        duration: 0.8,
        y: 0,
        opacity: 1,
        scrollTrigger: {
          trigger: el,
          start: 'top bottom-=90',
        },
      }
    );
  });
  return (
    <div
      ref={headerRef}
      className='h-screen lg:h-[70vh] container mx-auto rounded-none lg:rounded-xl overflow-hidden w-full services-background relative flex flex-col justify-center items-center'
    >
      <h2 className='text-center text-4xl lg:mt-0 mt-10 lg:text-7xl text-white font-bold tracking-wide p-5 z-10 '>
        Güvenilir Temizlik ve inşaat hizmeti
      </h2>
      <p className='tracking-widest z-10 mt-10 text-slate-50 max-w-5xl text-sm text-center leading-loose'>
        "Profesyonel ve güvenilir temizlik ve inşaat hizmetleri sunuyoruz.
        Müşterilerimize sağladığımız kaliteli hizmetlerle yaşam alanlarınızı ve
        projelerinizi en iyi şekilde şekillendiriyoruz. Deneyimli ekibimiz ve
        titiz çalışma prensibimizle, hayallerinizi gerçeğe dönüştürmek için
        buradayız."
      </p>

      <section className='mt-12 z-10 gap-10 flex lg:flex-row flex-col justify-center items-center'>
        <Button title='Hakkımızda' link='/about' />
        <Button title='İletişim' link='/about' filled={true} />
      </section>
      <HeaderDarkFilter />
    </div>
  );
};

export default WhatWeDo;
