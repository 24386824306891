import React, { useEffect, useRef, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Autoplay } from 'swiper/modules';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
// Import Swiper styles
import 'swiper/css';
import { cleaningProject } from '../../constant/project';
gsap.registerPlugin(ScrollTrigger);

const HomeProjects = () => {
  const [width, setWidth] = useState(1024);
  const sliderRef = useRef();
  useEffect(() => {
    const el = sliderRef.current;
    gsap.fromTo(
      el,
      {
        duration: 0.8,
        opacity: 0,
        y: 100,
      },
      {
        duration: 0.8,
        opacity: 1,
        y: 0,
        scrollTrigger: {
          trigger: el,
          start: 'top bottom-=80',
        },
      }
    );

    setWidth(window.innerWidth);
    window.addEventListener('resize', () => setWidth(window.screen.width));

    return () => {
      window.removeEventListener('resize', () => setWidth(window.screen.width));
    };
  }, []);
  return (
    <div className=' my-20 w-full container mx-auto h-full pb-10 '>
      <h2 className='text-center text-5xl text-sky-500 font-semibold py-24'>
        Projelerimiz
      </h2>

      <Swiper
        ref={sliderRef}
        loop={true}
        spaceBetween={50}
        autoplay={{
          delay: 1100,
          disableOnInteraction: false,
        }}
        slidesPerView={width > 1020 ? 3 : width > 900 ? 2 : 1}
        modules={[Pagination, Autoplay]}
        className='slider'
      >
        {cleaningProject.map((cleaning) => (
          <SwiperSlide key={cleaning.id}>
            <div className='w-full h-full flex justify-center relative items-center border shadow rounded-lg overflow-hidden'>
              <div className='w-full h-56'>
                <img
                  src={cleaning.img}
                  alt={cleaning.title}
                  className='w-full h-full object-cover'
                />
              </div>
              <div className='h-full bg-black/50 absolute transform -translate-y-1/2 left-1/2 top-1/2 -translate-x-1/2 w-full' />
              <div className='tracking-wider z-10 px-3 absolute transform -translate-y-1/2 left-1/2 top-1/2 -translate-x-1/2 w-full '>
                <h2 className='text-2xl mb-2.5 text-white'>{cleaning.title}</h2>

                <p className='text-slate-200 text-sm'>{cleaning.desc}</p>
              </div>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default HomeProjects;
