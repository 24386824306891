import React from 'react';
import { Link } from 'react-router-dom';

const FooterCopyright = () => {
  return (
    <span className='block text-sm text-gray-500 sm:text-center'>
      © 2024{' '}
      <Link to='/' className='hover:underline'>
        Yapittem
      </Link>
      . All Rights Reserved.
    </span>
  );
};

export default FooterCopyright;
