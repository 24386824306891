import React from 'react';
import logo from '../../assets/yap_tem.png';
const Project = () => {
  return (
    <div className=' max-w-lg lg:max-w-2xl z-10 w-full rounded-lg bg-white text-gray-600 text-center px-3 pt-12 pb-4 absolute -translate-x-1/2 shadow-lg  translate-y-1/2 transform  bottom-0 left-1/2 '>
      <img
        src={logo}
        alt='Yapıttem'
        className='w-20 mx-auto absolute bg-white top-0 -translate-x-1/2 -translate-y-1/2 transform left-1/2 p-2 rounded-full'
      />
      <p className='px-8'>
        Misyonumuz, müşterilerimize hijyenik ve sağlıklı yaşam alanları sunmak
        için kaliteli temizlik hizmetleri sağlamaktır. Her müşterimizin
        ihtiyaçlarını anlayarak, özelleştirilmiş çözümler sunarız. Müşteri
        memnuniyetini esas alarak, temizlik sektöründe öncü olmayı hedefliyoruz.
      </p>
    </div>
  );
};

export default Project;
