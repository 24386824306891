import React from 'react';
import { navbarLinks } from '../../../../../constant/navbar';
import { Link } from 'react-router-dom';

const NavbarMobile = ({ isActive, setIsActive }) => {
  return (
    <div
      className={`w-full relative flex bg-opacity-95 flex-col fixed-center duration-200 gap-3 h-screen text-slate-100 justify-center items-center
    ${isActive ? ' z-30  bg-white opacity-100' : '-z-30 opacity-0 '}
    `}
    >
      {navbarLinks.map((item) => (
        <Link
          className=' duration-200 text-slate-900 text-xl font-semibold  pt-12 w-full text-center'
          to={item.link}
          key={item.id}
          onClick={() => setIsActive(false)}
        >
          {item.title}
        </Link>
      ))}
    </div>
  );
};

export default NavbarMobile;
