import React from 'react';
import { IoIosMail } from 'react-icons/io';
import { Link } from 'react-router-dom';
import { FaPhoneAlt } from 'react-icons/fa';
import { IoLogoWhatsapp } from 'react-icons/io';

const NavbarTop = ({ navbar }) => {
  return (
    <nav
      className={`w-full text-sm px-3 lg:block hidden   h-full py-3 ${
        navbar ? "  bg-white" : "bg-transparent text-white "
      }`}
    >
      <section className="container duration-300 mx-auto flex justify-between items-center">
        <Link
          to="mailto:info@yapittem.com"
          className="flex items-center gap-1 tracking-wide   hover:text-sky-500 duration-100"
        >
          <IoIosMail size={23} /> <span>info@yapittem.com</span>
        </Link>
        <div className=" flex justify-center items-center gap-8">
          <Link
            to="tel:02164930207"
            className="flex items-center tracking-wide  gap-1  hover:text-sky-500 duration-100"
          >
            <FaPhoneAlt size={18} /> <span>+90 216 493 02 07 </span>
          </Link>

          <Link
            to='https://wa.me/05427831455?text=Merhaba,%0Asüreç%20ile%20ilgili%20bilgi%20alabilir%20miyim%20?'
            className='flex items-center tracking-wide  gap-1  hover:text-sky-500 duration-100'
          >
            <IoLogoWhatsapp size={20} /> <span>05427831455</span>
          </Link>
        </div>
      </section>
    </nav>
  );
};

export default NavbarTop;
