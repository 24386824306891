export const navbarBackgroundHandler = (setNavbar, isActive) => {
  if (isActive) {
    setNavbar(true);
  } else {
    if (window.scrollY >= 80) {
      setNavbar(true);
    } else {
      setNavbar(false);
    }
  }
};

export const UpdateWindowDimensions = (setIsActive) => {
  const newWidth = window.innerWidth;

  if (newWidth >= 1024) {
    setIsActive(false);
  }
  return newWidth;
};
