import React from "react";

const AboutDesc = ({desc}) => {
  return (
    <div className="mt-7 aboutSection2">
    {desc}
    </div>
  );
};

export default AboutDesc;
