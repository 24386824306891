import React from 'react';
import logo from '../../../../../../assets/yap_tem.png';
import { Link } from 'react-router-dom';
const NavbarLogo = () => {
  return (
    <Link to='/'>
      <img alt='Yapıtem İnşaat' src={logo} className='w-14 lg:w-20 h-full' />
    </Link>
  );
};

export default NavbarLogo;
