export const navbarLinks = [
  {
    id: 1,
    link: '/',
    title: 'Anasayfa',
  },
  {
    id: 2,
    link: '/about',
    title: 'Hakkımızda',
  },
  {
    id: 3,
    link: '/references',
    title: 'Referanslar',
  },
  {
    id: 4,
    link: '/cleaning',
    title: 'Temizlik Hizmetleri',
  },
  {
    id: 5,
    link: '/building',
    title: 'İnşaat Hizmetleri',
  },
  {
    id: 6,
    link: '/contact',
    title: 'İletişim',
  },
];
