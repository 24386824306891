import React from 'react';
import background from '../assets/slider2.jpg';
import HeaderDarkFilter from '../components/Home/HeaderDarkFilter';
import Button from '../components/common/button/Button';

const NotFound = () => {
  return (
    <div className='w-full h-screen justify-center items-center flex relative'>
      <HeaderDarkFilter />
      <img src={background} className='w-full h-full object-cover' alt='' />

      <div className='w-full lg:max-w-5xl  h-fit text-center text-orange-500  absolute transform z-20 -translate-y-1/2 -translate-x-1/2 top-1/2 left-1/2'>
        <h2 className='text-9xl lg:text-[200px] font-extrabold tracking-wide'>
          404!
        </h2>
        <p className='mb-10 text-slate-100 tracking-wide  leading-relaxed'>
          "Oops! Burada olmamanız gerektiğini düşünüyoruz. Aradığınız sayfa
          kaybolmuş gibi görünüyor. Lütfen geri dönün ve doğru yolu bulmamıza
          yardımcı olun veya ana sayfaya giderek başka bir yere yönelin.
          Teşekkürler!"
        </p>
        <Button title='Anasayfa' link='/' />
      </div>
    </div>
  );
};

export default NotFound;
