import React, { useEffect } from 'react';
import Header from '../components/common/header/Header';
import header from "../assets/slider3.jpg"
import Contact from '../components/Home/Contact';
import aboutImage from "../assets/slider1.jpg"
import AboutDesc from '../components/About/AboutDesc';
import { aboutValue } from '../constant/about';
const AboutPage = () => {
useEffect(()=>{
  window.scrollTo(0,0)
})

  return <div>
    <Header title="Hakkımızda"  src={header}/>

    <div className='w-full h-full py-10   container mx-auto grid grid-cols-1 lg:grid-cols-2 gap-16 justify-center items-center'>
      <img src={aboutImage} alt='' className='object-cover shadow-lg rounded' />

      <div className='flex tracking-wide leading-loose text-justify px-6 gap-8 lg:px-0 lg:text-start flex-1 flex-col justify-center items-start'>
          {
            aboutValue.map((about)=>(
              <AboutDesc desc={about.desc} key={about.id} />
            ))
          }
        
        <AboutDesc />
   
      </div>
    </div>

    <Contact />

  </div>;
};

export default AboutPage;
