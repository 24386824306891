import const1 from '../assets/koc.jpg';
import const2 from '../assets/dis.jpeg';
import const3 from '../assets/havuz.jpg';
import const4 from '../assets/gokkusagi.jpg';

export const cleaningProject = [
  {
    id: 1,
    title: 'Koç Üniversitesi',
    desc: "Uzman ekiplerimiz, Koç Üniversitesi'nin dış cephe temizliği ihtiyaçlarını en üst düzeyde karşılayarak binaların uzun ömürlü ve temiz bir görünüm kazanmasını sağlamıştır.",
    img: const1,
  },
  {
    id: 2,
    title: 'Gaming Gen TR',
    desc: "Gaming Gen TR'nin deposu tadilatı hizmeti, sektörün en son trendlerine uygun yapılarak markanın dijital varlığını güçlendirir ve hedef kitleye etkili bir şekilde ulaşmasını sağlar.",
    img: const2,
  },
  {
    id: 3,
    title: 'Villa Havuzu',
    desc: 'Uzman ekibimiz, son teknoloji ve çevre dostu temizlik yöntemleriyle müşterilerin havuzlarını kristal berraklığında tutar, sağlıklı ve keyifli bir yüzme deneyimi sunar.',
    img: const3,
  },
  {
    id: 4,
    title: 'Gökkuşağı 2000 ',
    desc: 'Gökkuşağı 2000 ,dış yüzey temizliği konusunda deneyimli kadrosuyla, modern teknikler ve çevre dostu ürünler kullanarak yapıların dış görünümünü koruruz.',
    img: const4,
  },
];
