import React from 'react';
import Button from '../common/button/Button';

const ContactCard = ({ icon, title, desc, btnTitle, link }) => {
  return (
    <div className='max-w-xl text-sky-500  bg-white px-4 py-7 rounded-lg flex flex-col justify-center items-center text-center'>
      {icon}

      <h2 className='text-2xl font-semibold text-sky-500 my-3 lg:my-6'>
        {title}
      </h2>
      <p className='tracking-wide text-sm px-6 mb-4 leading-relaxed text-slate-500'>
        {desc}
      </p>

      <Button title={btnTitle} type='outline' link={link} />
    </div>
  );
};

export default ContactCard;
