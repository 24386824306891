import { GrLocation } from 'react-icons/gr';
import { IoMailOpenOutline } from 'react-icons/io5';
import { MdOutlineLocalPhone } from 'react-icons/md';

export const contactCard = [
  {
    id: 1,
    title: 'Bizi Arayın!',
    desc: `Sorularınız mı var? Yardıma mı ihtiyacınız var? İletişime geçmekten çekinmeyin! Profesyonel ve dostça ekibimiz, size yardımcı olmaktan memnuniyet duyacaktır.`,
    btnTitle: '+90 216 493 02 07',
    btnLink: 'tel:02164930207',
    icon: (
      <MdOutlineLocalPhone size={70} className='text-sky-500 md:block hidden' />
    ),
  },
  {
    id: 2,
    title: 'Bize Mail Atın!',
    desc: `Herhangi bir soru, öneri veya geri bildirim mi var? Size ulaşmaktan
    memnuniyet duyarız! İletişim formumuzu kullanarak veya doğrudan
    e-posta göndererek bize ulaşabilirsiniz.`,
    btnTitle: 'info@yapittem.com',
    btnLink: 'mailto:info@yapittem.com',
    icon: (
      <IoMailOpenOutline size={70} className='text-sky-500 md:block hidden' />
    ),
  },
];

export const contactPage = [
  {
    id: 1,
    title: 'Bizi Arayın!',
    desc: `Bizi arayın! İhtiyaçlarınızı dinlemek ve size en iyi hizmeti
    sunmak için buradayız. 
    Size yardımcı olmaktan mutluluk duyacağız.`,
    btnTitle: '+90 216 493 02 07',
    btnLink: 'tel:02164930207',
    icon: (
      <MdOutlineLocalPhone size={70} className='text-sky-500 md:block hidden' />
    ),
  },
  {
    id: 2,
    title: 'Bize Mail Atın!',
    desc: `Bizi arayın! İhtiyaçlarınızı dinlemek ve size en iyi hizmeti
    sunmak için buradayız. Size yardımcı olmaktan mutluluk duyacağız.`,
    btnTitle: 'info@yapittem.com',
    btnLink: 'mailto:info@yapittem.com',
    icon: (
      <IoMailOpenOutline size={70} className='text-sky-500 md:block hidden' />
    ),
  },
  {
    id: 3,
    title: 'Bize Ulaşın!',
    desc: `Harita bölümü, yerimizi bulmanızı kolaylaştırır. Size en yakın
    noktaları gösterir. Yol tarifi için haritayı kullanabilirsiniz.`,
    btnTitle: 'Haritalarda Aç',
    btnLink:
      "https://www.google.com/maps/place/40%C2%B059'28.8%22N+29%C2%B001'58.6%22E/@40.991344,29.032952,17z/data=!3m1!4b1!4m4!3m3!8m2!3d40.991344!4d29.032952?entry=ttu",
    icon: <GrLocation size={63} className='text-sky-500 md:block hidden' />,
  },
];
