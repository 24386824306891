import React from "react";
import Hamburger from 'hamburger-react'


const HamburgerMenu = ({ isActive, setIsActive }) => {

  return (
    <span  className="lg:hidden block z-50 text-white">
      <Hamburger toggled={isActive} toggle={setIsActive} />
    </span>
  )
};

export default HamburgerMenu;
