import React from 'react'

const HeaderDarkFilter = () => {
  return (
    <div className='w-full h-full bg-black z-[2] absolute -translate-x-1/2 -translate-y-1/2 transform  top-1/2 left-1/2 opacity-50' />
      

  )
}

export default HeaderDarkFilter
