import React, { useEffect, useRef } from 'react';
import Header from '../components/common/header/Header';
import headerImage from '../assets/slider2.jpg';
import { cleaningServices } from '../constant/service';
import ServiceCard from '../components/Home/ServiceCard';
import gsap from 'gsap';

const CleaningServices = () => {
  const titleRef = useRef();

  useEffect(() => {
    window.scrollTo(0, 0);
    const tl = (titleRef.current = gsap.timeline({
      defaults: {
        delay: -0.25,
        duration: 0.5,
        ease: 'back.out(1.7)',
      },
    }));

    cleaningServices.map((item, index) => {
      index += 1;
      tl.fromTo(
        `#service${index}`,
        { autoAlpha: 0, y: 50 },
        { autoAlpha: 1, y: 0 }
      );
    });
  }, []);

  return (
    <div>
      <Header title='Temizlik Hizmetleri' src={headerImage} />

      <div
        ref={titleRef}
        className='w-full h-full py-12 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 justify-center items-center gap-10 container mx-auto'
      >
        {cleaningServices.map((service) => (
          <ServiceCard
            id={service.id}
            desc={service.desc}
            src={service.src}
            title={service.title}
            key={service.id}
          />
        ))}
      </div>
    </div>
  );
};

export default CleaningServices;
