import React from 'react';
import { navbarLinks } from '../../../constant/navbar';
import FooterLogo from './components/footerLogo/FooterLogo';
import FooterCopyright from './components/footerCopyright/FooterCopyright';
import FooterStick from './components/footerStick/FooterStick';
import FooterLink from './components/footerLink/FooterLink';

const Footer = () => {
  return (
    <footer className='bg-yapıtem-100'>
      <div className='w-full max-w-screen-xl mx-auto p-4 md:py-8'>
        <div className='sm:flex sm:items-center sm:justify-between'>
          <FooterLogo />
          <ul className='flex flex-wrap items-center mb-6 text-sm font-medium text-slate-300 sm:mb-0 '>
            {navbarLinks.map((footer) => (
              <FooterLink
                title={footer.title}
                link={footer.link}
                key={footer.id}
              />
            ))}
          </ul>
        </div>
        <FooterStick />
        <FooterCopyright />
      </div>
    </footer>
  );
};

export default Footer;
