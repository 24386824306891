import React, { useEffect } from 'react';
import Header from '../components/Home/Header';
import About from '../components/Home/About';
import Contact from '../components/Home/Contact';
import Services from '../components/Home/Services';
import HomeProjects from '../components/Home/HomeProjects';
import WhatWeDo from '../components/Home/WhatWeDo';

const Home = () => {
  useEffect(()=>{
    window.scrollTo(0,0)
  })
  return (
    <div>
      <Header />
      <HomeProjects />
      <WhatWeDo />
      <Services />
      <About />
      <Contact />
    </div>
  );
};

export default Home;
